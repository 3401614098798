function kml(coordinates, orderID) {
    coordinates = JSON.stringify(coordinates);
    orderID = 'FF' + orderID;
    coordinates = coordinates.replace(/"lng":/g, "");
    coordinates = coordinates.replace(/"lat":/g, "");
    coordinates = coordinates.replace(/[{\[\]}]/g, "");
    coordinates = coordinates.trim();
    coordsarray = coordinates.split(",");
    coordsarray = coordsarray.filter(function(n) {
        return n != ""
    });
    if (coordsarray.length % 2 != 0) {
        alert("nicht alles Koordinatenpaare");
    } else if (coordsarray.length > 2) {
        write = "";
        for (i = 1; i < coordsarray.length; i = i + 2) {
            write = write.concat(coordsarray[i], ",", coordsarray[i - 1], " ");
        }
        write = write.concat(coordsarray[1], ",", coordsarray[0]);
        poly1 = '<?xml version="1.0" encoding="UTF-8"?><kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom"><Document>	<name>' + orderID + '_export.kml</name>	<StyleMap id="m_ylw-pushpin">		<Pair>			<key>normal</key>			<styleUrl>#s_ylw-pushpin</styleUrl>		</Pair>		<Pair>			<key>highlight</key>			<styleUrl>#s_ylw-pushpin_hl</styleUrl>		</Pair>	</StyleMap>	<Style id="s_ylw-pushpin">		<IconStyle>			<scale>1.1</scale>			<Icon>				<href>http://maps.google.com/mapfiles/kml/pushpin/ylw-pushpin.png</href>			</Icon>			<hotSpot x="20" y="2" xunits="pixels" yunits="pixels"/>		</IconStyle>		<PolyStyle>			<color>8014F000</color>		</PolyStyle>	</Style>	<Style id="s_ylw-pushpin_hl">		<IconStyle>			<scale>1.3</scale>			<Icon>				<href>http://maps.google.com/mapfiles/kml/pushpin/ylw-pushpin.png</href>			</Icon>			<hotSpot x="20" y="2" xunits="pixels" yunits="pixels"/>		</IconStyle>		<PolyStyle>			<color>8014F000</color>		</PolyStyle>	</Style>	<Placemark>		<name>' + orderID + '_export</name>		<styleUrl>#m_ylw-pushpin</styleUrl>		<Polygon>			<tessellate>1</tessellate>			<outerBoundaryIs>				<LinearRing>					<coordinates>';
        poly2 = '</coordinates>				</LinearRing>			</outerBoundaryIs>		</Polygon>	</Placemark></Document></kml>';
        D = document, a = D.createElement("a"), d = poly1.concat(write, poly2), n = orderID.concat('_export.kml'), t = 'application/vnd.google-earth.kml+xml';
        a.href = "data:" + t + "charset=utf-8," + escape(d);
        if (window.MSBlobBuilder) {
            bb = new MSBlobBuilder();
            bb.append(d);
        }
        if ('download' in a) {
            a.setAttribute("download", n);
            a.innerHTML = "downloading...";
            D.body.appendChild(a);
            setTimeout(function() {
                e = D.createEvent("MouseEvents");
                e.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                a.dispatchEvent(e);
                D.body.removeChild(a);
            }, 66);
        };
        f = D.createElement("iframe");
        D.body.appendChild(f);
        f.src = "data:" + ('export.kml' ? 'export.kml' : "application/octet-stream") + (window.btoa ? ";base64" : "") + "," + (window.btoa ? window.btoa : escape)(d);
        setTimeout(function() {
            D.body.removeChild(f);
        }, 333);
        // alert("Exportiert als Polygon");
    } else if (coordsarray.length == 2) {
        var pin1 = '<?xml version="1.0" encoding="UTF-8"?><kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom"><Document>	<name>' + orderID + '_export.kml</name>	<Style id="s_ylw-pushpin_hl">		<IconStyle>			<scale>1.3</scale>			<Icon>				<href>http://maps.google.com/mapfiles/kml/pushpin/ylw-pushpin.png</href>			</Icon>			<hotSpot x="20" y="2" xunits="pixels" yunits="pixels"/>		</IconStyle>	</Style>	<Style id="s_ylw-pushpin">		<IconStyle>			<scale>1.1</scale>			<Icon>				<href>http://maps.google.com/mapfiles/kml/pushpin/ylw-pushpin.png</href>			</Icon>			<hotSpot x="20" y="2" xunits="pixels" yunits="pixels"/>		</IconStyle>	</Style>	<StyleMap id="m_ylw-pushpin">		<Pair>			<key>normal</key>			<styleUrl>#s_ylw-pushpin</styleUrl>		</Pair>		<Pair>			<key>highlight</key>			<styleUrl>#s_ylw-pushpin_hl</styleUrl>		</Pair>	</StyleMap>	<Placemark>		<name>' + orderID + '_export</name>		<Point>			<gx:drawOrder>1</gx:drawOrder>			<coordinates>';
        var pin2 = '</coordinates>		</Point>	</Placemark></Document></kml>';
        D = document, a = D.createElement("a"), d = pin1.concat(coordsarray[1], ",", coordsarray[0], pin2), n = orderID.concat('_export.kml'), t = 'application/vnd.google-earth.kml+xml';
        a.href = "data:" + t + "charset=utf-8," + escape(d);
        if (window.MSBlobBuilder) {
            bb = new MSBlobBuilder();
            bb.append(d);
        }
        if ('download' in a) {
            a.setAttribute("download", n);
            a.innerHTML = "downloading...";
            D.body.appendChild(a);
            setTimeout(function() {
                e = D.createEvent("MouseEvents");
                e.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                a.dispatchEvent(e);
                D.body.removeChild(a);
            }, 66);
        };
        f = D.createElement("iframe");
        D.body.appendChild(f);
        f.src = "data:" + ('export.kml' ? 'export.kml' : "application/octet-stream") + (window.btoa ? ";base64" : "") + "," + (window.btoa ? window.btoa : escape)(d);
        setTimeout(function() {
            D.body.removeChild(f);
        }, 333);
        // alert("Exportiert als Pin");
    } else {
        alert("ERROR: no coordinates / not right format / unknown");
    }
}